import { get, put, download, post, destroy, BaseUrl } from "./api";
import * as Base64 from "base64-js";

const controller = 'TemplateFile';

export const getInteractTemplateFile = async (id) => {
  return await get(`${controller}/${id}`);
}

export const getTemplateDocuments = async (id) => {
  return await get(`${controller}/template/${id}/docs`);
}

export const getTemplateDocumentsEx = async (id) => {
  return await get(`${controller}/template/${id}/docsex`);
}

export const getInteractTemplateFileVersions = async (id) => {
  return await get(`${controller}/${id}/versions`);
}

export const rollbackInteractTemplateFileVersion = async (id, versionId) => {
  return await get(`${controller}/${id}/version/${versionId}/rollback`);
}

export const uploadInteractTemplateFile = async (templateEnvironementId, fileName, fileSize, fileDate, comment, content, fileOrigin) => {
  var data = {
    templateEnvironementId: templateEnvironementId,
    fileName: fileName,
    fileSize: fileSize,
    fileDate: fileDate,
    fileOrigin: fileOrigin,
    comment: comment,
    base64Content: content
  };

  return await put(`${controller}/upload`, data);
}

export const uploadInteractTemplateFileChunked = async (templateEnvironementId, fileName, fileSize, fileDate, comment, content, fileOrigin) => {
  const rawContent = Base64.toByteArray(content);
  const chunkSize = 1048576 * 1; //its 1MB, increase the number measure in mb
  const chunkCount = rawContent.length % chunkSize == 0
    ? rawContent.length / chunkSize
    : Math.floor(rawContent.length / chunkSize) + 1; // Total count of chunks will have been upload to finish the file

  const initData = {
    templateEnvironementId: templateEnvironementId,
    fileName: fileName,
    fileSize: fileSize,
    fileDate: fileDate,
    fileOrigin: fileOrigin,
    comment: comment,
    chunkCount: chunkCount
  };

  return uploadChunkedFile(`${controller}/initupload`, initData, chunkSize, chunkCount, rawContent);
}

const uploadChunkedFile = async (urlInit, initData, chunkSize, chunkCount, rawContent) => {
  const contextId = await post(urlInit, initData);

  if (contextId)
  {
    let beginingOfTheChunk = 0;
    let endOfTheChunk = chunkSize;

    let counter = 0;
    while (counter < chunkCount) {
      var chunkData = Base64.fromByteArray(rawContent.slice(beginingOfTheChunk, endOfTheChunk));

      const chunkOk = await post(`${controller}/uploadchunk`,{
        contextId: contextId,
        chunkId: counter,
        chunkSize: chunkData.length,
        base64Content: chunkData,
      });

      if (chunkOk) {
        beginingOfTheChunk = endOfTheChunk;
        endOfTheChunk += chunkSize;

        if (counter == (chunkCount - 1)) {
          // console.log("Process is complete, counter", counter);
          return await post(`${controller}/finalizeupload`, {contextId: contextId});
        }
      }
      else{
        // console.log("chunkOk === false");
        return null;
      }

      ++counter;
    }
  }

  return null;
}

export const getDownloadsFromTemplateFile = async (fileId, fileName) => {
  return await get(`${controller}/downloads/${fileId}`);
}

export const downloadInteractTemplateFile = async (fileId, fileName) => {
  return await download(`${controller}/download/${fileId}`, addFileExtensionIfNone(fileName));
}

export const downloadInteractStorageFile = async (storageId, fileName) => {
  return await download(`${controller}/download/sid/${storageId}`, addFileExtensionIfNone(fileName));
}

export const downloadInteractTemplateVersionFile = async (fileId, versionId, fileName) => {
  return await download(`${controller}/download/${fileId}/version/${versionId}`, addFileExtensionIfNone(fileName));
}

export const downloadServerBackupFile = async (templateId, fileId, fileName) => {
  return await download(`${controller}/backup/${templateId}/download/${fileId}`, addFileExtensionIfNone(fileName, '.zip'));
}

export const deleteInteractTemplateFile = async (fileId) => {
  return await destroy(`${controller}/${fileId}`);
}

export const deleteServerBackupFile = async (templateId, fileId) => {
  return await destroy(`${controller}/backup/${templateId}/${fileId}`);
}

const addFileExtensionIfNone = (filename, ext = '.mfl') => {
  const fileExt = getExtension(filename);
  return !fileExt || fileExt === "" ? addFileExtension(filename, ext) : filename;
}

const addFileExtension = (filename, ext = '.mfl') => {
  return !filename.toLowerCase().endsWith(ext)
    ? filename + ext
    : filename;
}

const getExtension = str =>
{
   const idx = str.lastIndexOf(".");
   if (idx >= 0)
    return str.slice(idx);
  return "";
};

export const uploadInteractServerBackupFileChunked = async (templateEnvironementId, machineIdentity, fileName, fileSize, fileDate, content) => {

  // console.log("uploadInteractServerBackupFileChunked", templateEnvironementId, machineIdentity, fileName, fileSize, fileDate, content);

  const rawContent = Base64.toByteArray(content);
  const chunkSize = 1048576 * 1; //its 1MB, increase the number measure in mb
  const chunkCount = rawContent.length % chunkSize == 0
    ? rawContent.length / chunkSize
    : Math.floor(rawContent.length / chunkSize) + 1; // Total count of chunks will have been upload to finish the file

  const initData = {
    templateEnvironementId: templateEnvironementId,
    machineIdentity: machineIdentity,
    fileName: fileName,
    fileSize: fileSize,
    fileDate: fileDate,
    chunkCount: chunkCount
  };

  return uploadChunkedFile(`${controller}/initbackupupload`, initData, chunkSize, chunkCount, rawContent);
}


export const uploadInteractServerFileChunked = async (fileType, dependencyType, dependencyId, fileName, fileSize, fileDate, comment, content, fileOrigin) => {

  // console.log("uploadInteractServerFileChunked", fileType, dependencyType, dependencyId, fileName, fileSize, fileDate, comment, fileOrigin, content);

  const rawContent = Base64.toByteArray(content);
  const chunkSize = 1048576 * 1; //its 1MB, increase the number measure in mb
  const chunkCount = rawContent.length % chunkSize == 0
    ? rawContent.length / chunkSize
    : Math.floor(rawContent.length / chunkSize) + 1; // Total count of chunks will have been upload to finish the file

  const initData = {
    fileType: fileType,
    dependencyType: dependencyType,
    dependencyId: dependencyId,
    fileOrigin: fileOrigin,
    comment: comment,
    fileName: fileName,
    fileSize: fileSize,
    fileDate: fileDate,
    chunkCount: chunkCount
  };

  return uploadChunkedFile(`${controller}/initsrvfileupload`, initData, chunkSize, chunkCount, rawContent);
}

export const updateAttachmentTemplate = async (fileId, attachmentTemplateId) => {
  const data = {
    attachmentTemplateId: attachmentTemplateId
  };
  return await put(`${controller}/${fileId}/attachment`, data);
}
