import React, { useEffect, useState, useRef } from 'react';
import { format } from "date-fns";

import * as TemplateFileApi from "../../../api/interactTemplateFile.js";
import * as TemplateEnvApi from "../../../api/interactTemplateEnvironment.js";

import TemplateFileTableRow from "./templateFileTableRow"

import MenuContextList from "../../../components/MenuContextList/index.js";
import { TwTable } from "../../../components/TailwindTable/index.js";
import { Tabs as CmpTabs } from "../../../components/Tabs/index.js";
import { Button as CmpButton } from "../../../components/Button/index.js";
import { Select as CmpSelect } from "../../../components/Select/index.js";
import GenericModal from '../../../components/Modals/genericModal.js';

export default function FileInfoDialog({fileInfo, openShowFileInfo, setOpenShowFileInfo, hasCrudAccess, loadBaseData}) {
  const [activeTab, setActiveTab] = useState("versions");
  const [availableTemplates, setAvailableTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [attachmentTemplateId, setAttachmentTemplateId] = useState(null);
  const [initialAttachmentTemplateId, setInitialAttachmentTemplateId] = useState(null);

  const refMenuContainer = useRef();

  const tabs = [{
    name: "Versions",
    tab: "versions",
    current: activeTab === "versions"
  },{
    name: "Downloads",
    tab: "downloads",
    current: activeTab === "downloads"
  },{
    name: "Attachments",
    tab: "attachments",
    current: activeTab === "attachments"
  }];

  const [currentDialogHistoryData, setCurrentDialogHistoryData] = useState(null);
  const [currentDialogDownloadsData, setCurrentDialogDownloadsData] = useState(null);

  let defTotCnt = {};
  let defCurrPg = {};
  tabs.map(tx => {
    defTotCnt[tx.tab] = 0;
    defCurrPg[tx.tab] = 0;
  });

  const [paginationTotalCount, setPaginationTotalCount] = useState(defTotCnt);
  const [paginationCurrentPage, setPaginationCurrentPage] = useState(defCurrPg);
  const [paginationItemsPerPage, setPaginationItemsPerPage] = useState(3);

  const loadData = async () => {
    var file = fileInfo?.file;

    var versions = await TemplateFileApi.getInteractTemplateFileVersions(file.id);
    setCurrentDialogHistoryData({file, versions, pageData: versions.slice(0, paginationItemsPerPage)});
    
    // Load all available templates for the attachment dropdown
    const templates = await TemplateEnvApi.getInteractTemplateEnvironments();
    setAvailableTemplates(templates || []);

    // Set the current attachment template ID
    setAttachmentTemplateId(file.attachmentTemplateId || null);
    setInitialAttachmentTemplateId(file.attachmentTemplateId || null);
    
    // Find the selected template if one is set
    if (file.attachmentTemplateId) {
      const template = templates?.find(t => t.id === file.attachmentTemplateId);
      setSelectedTemplate(template || null);
    } else {
      setSelectedTemplate(null);
    }
    
    let downloads = await TemplateFileApi.getDownloadsFromTemplateFile(file.id);
    downloads = downloads.map(d => {
      d["serverName"] = fileInfo?.toTemplateEnvironment?.template?.servers.find(s => s.serverId === d.templateServertId)?.name;
      return d;
    });
    setCurrentDialogDownloadsData({file, collection: downloads, pageData: downloads.slice(0, paginationItemsPerPage)});

    console.log("FileInfoDialog", "loadData", "versions", versions?.length || 0, "downloads", downloads?.length || 0);

    setPaginationCurrentPage(prevState => ({
      ...prevState,
      ["versions"]: 0,
      ["downloads"]: 0
    }));
    setPaginationTotalCount(prevState => ({
      ...prevState,
      ["versions"]: versions?.length || 0,
      ["downloads"]: downloads?.length || 0
    }));
  }

  useEffect(() => {
    if (openShowFileInfo) {
      loadData();
    }
    else {
      setCurrentDialogHistoryData(null);
      setCurrentDialogDownloadsData(null);
    }
  }, [openShowFileInfo]);

  useEffect(() => {
    if (activeTab === "versions") {
      const start = paginationCurrentPage[activeTab] * paginationItemsPerPage;
      setCurrentDialogHistoryData(prevState => ({
        ...prevState,
        ["pageData"]: prevState?.versions?.slice(start, start + paginationItemsPerPage)
      }));
    }
    if (activeTab === "downloads") {
      const start = paginationCurrentPage[activeTab] * paginationItemsPerPage;
      setCurrentDialogDownloadsData(prevState => ({
        ...prevState,
        ["pageData"]: prevState?.collection?.slice(start, start + paginationItemsPerPage)
      }));
    }
  }, [paginationCurrentPage, paginationItemsPerPage]);

  const setPaginationCurrentPageEx = (key, value) => {
    setPaginationCurrentPage(prevState => ({
      ...prevState,
      [key]: value
    }));
  }

  const handleVersionRollback = async (versionId) => {
    await TemplateFileApi.rollbackInteractTemplateFileVersion(currentDialogHistoryData.file.id, versionId);
    setOpenShowFileInfo(false);
    await loadBaseData();
  }
  
  const downloadVersionFile = async (version) => {
    const fileName = `${version.record.fileName}_${version.record.versionInfo.replace('.', '-')}`;
    await TemplateFileApi.downloadInteractTemplateVersionFile(version.record.id, version.id, fileName);
  }

  // Add function to update the attachment template
  const updateAttachmentTemplate = async () => {
    if (initialAttachmentTemplateId !== attachmentTemplateId) {
      await TemplateFileApi.updateAttachmentTemplate(
        currentDialogHistoryData.file.id, 
        attachmentTemplateId
      );
      await loadBaseData();
      setInitialAttachmentTemplateId(attachmentTemplateId);
    }
  };

  const handleAttachmentTemplateChange = ({ name, value}) => {
    console.log('name -->', name)
    console.log('value -->', value)

    const template = availableTemplates.find(t => t.id === value);
    setSelectedTemplate(template);
    setAttachmentTemplateId(value);
  }

  const renderActiveTab = () => {
    if (!currentDialogHistoryData && !currentDialogDownloadsData)
      return <></>;

    switch (activeTab) {
      case "versions":
        {
          return (
            <div className="flex gap-8 mt-4 flow-root">
              <div className='flex-auto mb-4' ref={refMenuContainer}>
                <TwTable>
                  <TwTable.Head>
                    <TwTable.Header additionalClass={'sm:pl-6 h-14'}>
                      Name
                    </TwTable.Header>
                    <TwTable.Header additionalClass={'sm:pl-6'}>
                      Updated
                    </TwTable.Header>
                    <TwTable.Header/>
                  </TwTable.Head>
                  <TwTable.Body additionalClass='text-left' noDivider={true}>
                    {currentDialogHistoryData?.pageData && currentDialogHistoryData.pageData.map((version, index) => (
                          <TemplateFileTableRow
                          fileId={version.id}
                          fileData={version.record}
                          addDivider={index < currentDialogHistoryData.length - 1}
                          menuElem={
                            <>
                              {hasCrudAccess && (
                                <MenuContextList
                                  actions={[{
                                      name: "Rollback",
                                      onClick: () => handleVersionRollback(version.id),
                                    }, {
                                      name: "Download",
                                      onClick: () => downloadVersionFile(version),
                                    }]
                                  }
                                  handleDelete="false"
                                  refContainer={refMenuContainer}
                                />
                              )}
                              {!hasCrudAccess && (
                                <></> 
                              )}

                            </>
                          }
                        />
                    ))}
                  </TwTable.Body>
                    <TwTable.Foot>
                      <TwTable.FootCol colSpan="20">
                        <TwTable.Pagination 
                          numPages={Math.ceil(paginationTotalCount[activeTab] / paginationItemsPerPage)} 
                          currPage={paginationCurrentPage[activeTab]} 
                          setCurrPage={(v) => setPaginationCurrentPageEx(activeTab, v)} 
                          itemsPerPage={paginationItemsPerPage} 
                          setItemPerPage={setPaginationItemsPerPage} 
                          maxItems={paginationTotalCount[activeTab]} 
                          boundaryCount={2} 
                        />
                      </TwTable.FootCol>
                    </TwTable.Foot>
                </TwTable>
              </div>
            </div>          
          );
        }
      case "downloads":
        {
          return (
            <div className="flex gap-8 mt-4 flow-root">
              <div className='flex-auto mb-4'>
                <TwTable>
                  <TwTable.Head>
                    <TwTable.Header additionalClass={'sm:pl-6 h-14'}>
                      Server
                    </TwTable.Header>
                    <TwTable.Header additionalClass={'sm:pl-6'}>
                      Downloaded
                    </TwTable.Header>
                  </TwTable.Head>
                  <TwTable.Body additionalClass='text-left'>
                    {currentDialogDownloadsData?.pageData && currentDialogDownloadsData.pageData.map((file, index) => (
                      <>
                      <TwTable.BodyRow key={`ff${file.id}-m`} additionalClass="h-12" useHover={false}>
                        <TwTable.BodyCol>
                          {file.serverName}
                        </TwTable.BodyCol>
                        <TwTable.BodyCol>
                          {file.updatedDate && format(
                            new Date(file.updatedDate),
                            "MM/dd/yyyy - HH:mm"
                          )}
                        </TwTable.BodyCol>
                      </TwTable.BodyRow>
                      </>
                    ))}
                  </TwTable.Body>
                  {paginationTotalCount > paginationItemsPerPage && (
                    <TwTable.Foot>
                      <TwTable.FootCol colSpan="20">
                        <TwTable.Pagination 
                          numPages={Math.ceil(paginationTotalCount[activeTab] / paginationItemsPerPage)} 
                          currPage={paginationCurrentPage[activeTab]} 
                          setCurrPage={(v) => setPaginationCurrentPageEx(activeTab, v)} 
                          itemsPerPage={paginationItemsPerPage} 
                          setItemPerPage={setPaginationItemsPerPage} 
                          maxItems={paginationTotalCount[activeTab]} 
                          boundaryCount={2} 
                        />
                      </TwTable.FootCol>
                    </TwTable.Foot>
                  )}
                </TwTable>
              </div>
            </div>
          );
        }
      case "attachments":
        {
          return (
            <div className="flex gap-8 mt-4 flow-root">
              <div className='flex-auto mb-4'>
                <div className="mb-4">
                  <p className="text-sm mb-2 dark:text-white text-gray-800 p-4 rounded-md">
                    Connect this template to another template as a potential attachment. The system will always select the latest version of the attached template.
                  </p>
                  <div className="flex flex-col gap-4">
                    <CmpSelect
                      name="attachmentTemplate"
                      label="Template for Attachment"
                      options={availableTemplates.map(template => ({
                        value: template.id,
                        name: template.metaData.CustomerEnvironment || 'Please select a template'
                      }))}
                      value={attachmentTemplateId}
                      onChange={e => handleAttachmentTemplateChange(e.target)}
                      disabled={!hasCrudAccess}
                    />
                    {hasCrudAccess && (
                      <div className="flex justify-end">
                        <CmpButton
                          variant={CmpButton.variants.primary}
                          onClick={updateAttachmentTemplate}
                          disabled={initialAttachmentTemplateId === attachmentTemplateId}
                        >
                          Save Changes
                        </CmpButton>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          );
        }
      default:
        return <></>;
    }
  };

  return (
    <>
      {/* File information */}
      <GenericModal open={openShowFileInfo} setOpen={setOpenShowFileInfo}
        onConfirm={() => {
          setCurrentDialogDownloadsData(null);
          setOpenShowFileInfo(false);
        }}
        showCancelButton={false}
        confirmButtonText={'Ok'}
          title={`File information: ${fileInfo?.file?.fileName || ""}`}
      >
        {!!currentDialogHistoryData?.versions && (
          <>
          <CmpTabs tabs={tabs} onSelectedTab={(tab) => setActiveTab(tab)} />
          {renderActiveTab()}
          </>
        )}

      </GenericModal>
    </>
  );
}
