import React, { Fragment, useState } from "react";
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronDownIcon as ChevronUpDownIcon } from '@heroicons/react/outline'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

/**
 * @param {*} Select 
 */
export const Select = ({ label, name, value, options, onChange, disabled, error, useCustom = false }) => {
  const hasLabel = !!label;

  const defaultValue = useCustom ? options.filter(x => { return x.value === value})[0] : undefined;
  const [selected, setSelected] = useState(defaultValue);

  const onListBoxChange = (obj) => {
    setSelected(obj);
    value = obj.value;
    onChange({target: { name: name, value: obj.value}});
  }

  const getSelectedName = () => {
    return selected?.name || defaultValue?.name || "_";
  }

  return (
    <>
      <div className={classNames(hasLabel ? "space-y-1 sm:space-y-0 sm:py-1" : "", "sm:grid sm:grid-cols-1 sm:gap-3")}>
          {!useCustom && hasLabel &&
           <div>
            <label
              htmlFor="project_name"
              className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2 dark:text-gray-300"
            >
              {label}
            </label>
          </div>
          }
          <div className="sm:col-span-2">
            {useCustom ? 
              <Listbox value={selected} name={name} onChange={onListBoxChange} disabled={disabled}>
                {({ open }) => (
                  <>
                    <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-300">{label}</Listbox.Label>
                    <div className="relative mt-2">
                      <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                        {/* <span className="block truncate">{selected.name}</span> */}
                        <span className="block truncate">{getSelectedName()}</span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                          <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                        </span>
                      </Listbox.Button>

                      <Transition
                        show={open}
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                          {options?.map((option) => (
                            <Listbox.Option
                              key={`${option.name}${option.value}`}
                              className={({ active }) =>
                                classNames(
                                  active ? 'bg-indigo-600 text-white' : 'text-gray-900',
                                  'relative cursor-default select-none py-2 pl-3 pr-9'
                                )
                              }
                              value={option}
                            >
                              {({ selected, active }) => (
                                <>
                                  <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                    {option.name}
                                  </span>

                                  {selected ? (
                                    <span
                                      className={classNames(
                                        active ? 'text-white' : 'text-indigo-600',
                                        'absolute inset-y-0 right-0 flex items-center pr-4'
                                      )}
                                    >
                                      <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                    </span>
                                  ) : null}
                                </>
                              )}
                            </Listbox.Option>
                          ))}
                        </Listbox.Options>
                      </Transition>
                    </div>
                  </>
                )}
              </Listbox>
              :
              <select
                name={name}
                disabled={disabled}
                className={classNames(
                  "block w-full shadow-sm sm:text-sm focus:ring-brand-blue focus:border-brand-blue border-gray-300 rounded-md disabled:opacity-50 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-brand-blue dark:focus:border-brand-blue ",
                    error 
                    ? "placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 border-red-300 text-red-900 "
                    : ""
                )}
                value={value}
                onChange={(e) =>  {
                  onChange(e)
                }}
              >
                {
                  options?.map((option, index) => {
                    return (<option key={`${option.name}${option.value}`} value={option.value}>{option.name}</option>)
                  })
                }
              </select>
            }
            {
              error && 
              <p className="mt-2 text-sm text-red-600" id="email-error">
                {error}
              </p>
            }
          </div>
        </div>
    </>
  );
}